/* .gantt-container {
  height: calc(100vh - 50px - 200px);
} */
.gantt_grid_scale {
  background: linear-gradient(to bottom, #f5f7fa, #e8edf2) !important;
}
.gantt_task_scale {
  background: linear-gradient(to bottom, #f5f7fa, #e8edf2) !important;
}

.gantt_grid_head_cell,
.gantt_scale_cell {
  color: #1c3366 !important;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Helvetica Neue';
}
.gantt_tree_content {
  font-size: 0.875rem;
  font-weight: 400;
  font-family: 'Helvetica Neue';
}
.gantt_grid_head_add {
  visibility: hidden;
}
.gantt_cal_lsection {
  text-align: left !important;
  width: 170px !important;
  color: #000;
}
.gantt_section_time {
  padding: 25px 17px;
}
.gantt_cal_ltitle {
  background: linear-gradient(to bottom, #f5f7fa, #e8edf2) !important;
  color: #1c3366 !important;
  margin: -2px !important ;
  font-size: 0.875rem;
}
/* .gantt_cal_light_wide.gantt_cal_light_full {
  width: 45% !important;
  /* left: 52% !important; */
/* }  */

.gantt_cal_light select {
  font-size: 0.85rem;
  font-family: 'Helvetica Neue';
}
.gantt_cal_light_wide .gantt_cal_ltext {
  padding: 0;
}
.gantt_btn_set.gantt_save_btn_set {
  background: #409eff;
}
.gantt_btn_set.gantt_delete_btn_set {
  background: rgb(211, 47, 47);
  width: 14%;
}
.site_survey_set {
  background: #409eff;
  color: white;
}
.gantt_delete_btn_set div:nth-child(2) {
}
.gantt_delete_btn {
  visibility: hidden;
}
.gantt_popup_button.gantt_ok_button {
  background: #fff;
  border: 1px solid #409eff;
  color: #409eff;
}
.gantt_popup_button.gantt_cancel_button {
  background: #409eff;
  color: #fff;
}
.gantt_btn_set.gantt_save_btn_set {
  background: #409eff;
  color: white;
  width: 14%;
}

.gantt_btn_set.gantt_cancel_btn_set {
  background: #fff;
  border: 1px solid #409eff;
  color: #409eff;
  width: 14%;
}
.gantt_cancel_btn,
.gantt_save_btn {
  visibility: hidden;
}
