:not(:defined) > * {
    display: none;
  }

  body {
    margin: 0;
    font-family: Roboto Regular, Helvetica Neue, sans-serif;
  }

  #container {
    height: 100vh;
    width: 100vw;
  }

  .title {
    position: absolute;
    z-index: 999;
    margin-left: 20px;
  }

  #size {
    top: 50px;
  }
  
  #systemSize {
    top: 85px;
  }
  
  #features {
    top: 125px;
  }

  model-viewer {
    background-color: #eee;
    overflow-x: hidden;
    --poster-color: #eee;
    height: 100%;
    width: 100%;
  }

  #ar-button {
    background-image: url(../../assets/images/arIcon.webp);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 12px 50%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    bottom: 2px;
    padding: 0px 16px 0px 40px;
    font-family: Roboto Regular, Helvetica Neue, sans-serif;
    font-size: 14px;
    color:#4285f4;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: 1px solid #DADCE0;
  }

  #ar-button:active {
    background-color: #E8EAED;
  }

  #ar-button:focus {
    outline: none;
  }

  #ar-button:focus-visible {
    outline: 1px solid #4285f4;
  }

  @keyframes circle {
    from { transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); }
    to   { transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); }
  }

  @keyframes elongate {
    from { transform: translateX(100px); }
    to   { transform: translateX(-100px); }
  }

  model-viewer > #ar-prompt {
    position: absolute;
    left: 50%;
    bottom: 175px;
    animation: elongate 2s infinite ease-in-out alternate;
    display: none;
  }

  model-viewer[ar-status="session-started"] > #ar-prompt {
    display: block;
  }

  model-viewer > #ar-prompt > img {
    animation: circle 4s linear infinite;
  }

  model-viewer > #ar-failure {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 175px;
    display: none;
  }

  model-viewer[ar-tracking="not-tracking"] > #ar-failure {
    display: block;
  }

  .slider {
    width: 100%;
    text-align: center;
    overflow: hidden;
    position: absolute;
    bottom: 16px;
  }

  .slides {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    justify-content: center;
  }

  .slide {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 115px;
    height: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: #fff;
    margin-right: 10px;
    border-radius: 10px;
    border: 1px solid dimgrey;
    display: flex;
  }

  .slide.selected {
    border: 2px solid #4285f4;
  }

  .slide:focus {
    outline: none;
  }

  .slide:focus-visible {
    outline: 1px solid #4285f4;
  }
